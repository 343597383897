<template>
  <div class="home">
    <div class="banner">
      <!--            <a-carousel arrows :autoplay="autoplay">-->
      <!-- <div
          slot="prevArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="left: 10px; zindex: 1"
      >
          <a-icon type="left-circle" />
      </div>
      <div
          slot="nextArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="right: 10px"
      >
          <a-icon type="right-circle" />
      </div> -->
      <!--                <div class="bannerDiv" style="cursor:pointer;" @click="goPage">-->
      <!--                    <img-->
      <!--                        class="bannerImg"-->
      <!--                        src="../../assets/home/banner11.jpeg"-->
      <!--                        alt=""-->
      <!--                    />-->
      <!--                </div>-->
      <!--                <div class="bannerDiv" style="cursor:pointer;" @click="goActivityPage">-->
      <!--                    <img-->
      <!--                        class="bannerImg"-->
      <!--                        src="../../assets/home/banner10.png"-->
      <!--                        alt=""-->
      <!--                    />-->
      <!--                </div>-->
      <div class="bannerDiv">
        <!--                    <img-->
        <!--                        class="bannerImg"-->
        <!--                        src="../../assets/home/banner1.jpg"-->
        <!--                        alt=""-->
        <!--                    />-->
        <div class="bannerImg">
          <div class="mainTitle">素养植根融媒体智能学习系统</div>
          <div class="smallTitle">
            新科技赋能语文教育 新理念重构教学生态
          </div>
          <div class="useNow" @click="useNow">立即使用</div>
        </div>
      </div>
      <!--            </a-carousel>-->
    </div>
    <div class="moshi">
      <div class="title">模式变革 教师主导转型学生主体</div>
      <div class="mess">
        <div class="messTop">
          <div class="user"></div>
          <div class="speak">
            <div class="userName">王宁先生</div>
            <div class="userTitle">北京师范大学 资深教授</div>
            <div class="userMess">
              教育部高中语文课程标准首席专家
              <br/>教育部教材局中小学语文教材重点研究基地主任
            </div>
          </div>
          <div class="mainText">
            “ 语文学科核心素养必然是<span class="color"
          >以学生为主体</span
          >的，是通过学生自己积极主动的<span class="color"
          >语文实践活动</span
          >来形成和发展的。”
          </div>
        </div>
        <div class="messBot">
          <div>
            <div class="t_img1">
              <a-carousel autoplay :dots=false>
                <div>
                  <img
                    src="../../assets/home/6-1.png"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="../../assets/home/6-2.png"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="../../assets/home/6-3.png"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="../../assets/home/6-4.png"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="../../assets/home/6-5.png"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="../../assets/home/6-6.png"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="../../assets/home/6-7.png"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="../../assets/home/6-8.png"
                    alt=""
                  />
                </div>
              </a-carousel>
            </div>
            <div class="t_text">教师主导模式</div>
          </div>
          <div class="t_t"></div>
          <ul class="s_img">
            <li class="i8">
              <img
                class="c2"
                src="../../assets/home/c2.png"
                alt=""
              />
              <div class="l_t">精准教学</div>
            </li>
            <li class="i9">
              <img src="../../assets/home/c3.png" alt=""/>
              <div class="l_t">
                线上线下<br/>
                融合学习空间
              </div>
            </li>
            <li class="i10">
              <img src="../../assets/home/c4.png" alt=""/>
              <div class="l_t">过程性评价</div>
            </li>
            <li class="i11">
              <img src="../../assets/home/c5.png" alt=""/>
              <div class="l_t">领域专家</div>
            </li>
            <li class="i12">
              <img src="../../assets/home/c6.png" alt=""/>
              <div class="l_t">权威知识群</div>
            </li>
            <li class="i13">
              <img src="../../assets/home/c7.png" alt=""/>
              <div class="l_t">学习同伴</div>
            </li>
            <li class="i14">
              <img src="../../assets/home/c8.png" alt=""/>
              <div class="l_t">学习社区</div>
            </li>
            <li class="i15">
              <img src="../../assets/home/c9.png" alt=""/>
              <div class="l_t">精准学习活动</div>
            </li>
            <li class="iText">学生个性化、自主化学习模式</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="zhuanti">
      <div class="title">专题学习 助推语文核心素养生成</div>
      <div class="zt_mess">
        <div class="mainText">
          “ 作为一种对语文
          <span class="color"
          >课程内容、教学过程、学习方式和教学评价</span
          >进行深度整合的教学形态，<span class="color">专题学习</span>
          充分体现了互联网时代的特征。实践探索者所提供的许多具有推广价值的操作路径，无不指向调动学生内部动机，让学生真正成为学习的主人，以强烈的愿望、饱满的热情持久而深入地参与到学习活动中来。”
        </div>
        <div class="speak">
          <div class="userName">郑国民教授</div>
          <div class="userTitle">北京师范大学 资深教授</div>
          <div class="userMess">
            北京师范大学 未来教育学院院长 <br/>教材研究基地
            珠海研究中心主任
          </div>
        </div>
        <div class="user"></div>
      </div>
      <div class="zt_bot">
        <div class="botLeft">
          <span style="margin-right: 40px">语文核心素养</span>
        </div>
        <ul class="botRight">
          <li class="zt1">
            <div class="text">语言构建与应用</div>
            <div class="explain">
                            <span>
                                语言建构与运用是指学生在丰富的语言实践中，通过主动的积累、梳理和整合，逐步掌握祖国语言文字特点及其运用规律，形成个体言语经验，发展在具体语言情境中共正确有效地运用祖国语言文字进行交流沟通的能力。
                            </span>
            </div>
          </li>
          <li class="zt2">
            <div class="text">思维发展与提升</div>
            <div class="explain">
                            <span>
                                思维发展与提升是指学生在语文学习过程中，通过语言运用，获得直觉思维、形象思维、逻辑思维、辩证思维和创造思维的发展，以及深刻性、敏捷性、灵活性、批判性和独创性等思维品质的提升。
                            </span>
            </div>
          </li>
          <li class="zt3">
            <div class="text">审美鉴赏与创造</div>
            <div class="explain">
                            <span>
                                审美鉴赏与创造是指学生在语文学习中，通过审美体验、评价等活动形成正确的审美意识、健康向上的审美情趣与鉴赏品位，并在此过程中逐步掌握表现美、创造美的方法。
                            </span>
            </div>
          </li>
          <li class="zt4">
            <div class="text">文化传承与理解</div>
            <div class="explain">
                            <span>
                                文化传承与理解是指学生在语文学习中，继承和弘扬中华优秀传统文化、革命文化、社会主义先进文化，理解与借鉴不同民族和地区的文化，拓展文化视野，增强文化自觉，提升中国特色社会主义文化自信，热爱祖国语言文字，热爱中华文化，防止文化上的民族虚无主义。
                            </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="zhuanjia">
      <div class="bgc">
        <div class="ti">名师专家 创新高中语文阅读模式</div>
        <ul class="con">
          <li class="li1">
            <div class="zj1"></div>
            <div class="zj_t">任务驱动、活动引领的阅读模式</div>
          </li>
          <li class="li2">
            <div class="zj2"></div>
            <div class="zj_t">多维度、融媒体的资源表达</div>
          </li>
          <li class="li3">
            <div class="zj3"></div>
            <div class="zj_t">阅读行为过程性积累</div>
          </li>
          <li class="li4">
            <div class="zj4"></div>
            <div class="zj_t">基于评价的个性化推荐</div>
          </li>
          <li class="li5">
            <div class="zj5"></div>
            <div class="zj_t">交互式、共享式阅读共同体</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="lianhe">
      <div class="ti">联合研发 高中语文融媒体教材</div>
      <div class="cont">
        <ul class="lhUl">
          <li class="lhLi1">
            <div class="logoImg">
              <img
                class="imgLogo1"
                src="../../assets/img/10_1.png"
                alt=""
              />
            </div>
            <div class="logoName">
              <div class="nameDetail">山东人民出版社有限公司</div>
            </div>
          </li>
          <li class="lhLi3">
            <div class="logoImg">
              <img
                class="imgLogo2"
                src="../../assets/img/11_1.png"
                alt=""
              />
            </div>
            <div class="logoName">
              <div class="nameDetail">
                北京师范大学<br/>语文素养植根工程团队
              </div>
            </div>
          </li>
          <li class="lhLi5">
            <div class="logoImg">
              <img
                class="imgLogo3"
                src="../../assets/img/12_1.png"
                alt=""
              />
            </div>
            <div class="logoName">
              <div class="nameDetail">
                北京汉雅天诚<br/>教育科技有限公司
              </div>
            </div>
          </li>
        </ul>
        <div class="lhMess">
          以高中语文素养植根融媒体教材为研究课题，研发素养植根融媒体教材。
          <br/>
          该教材代表一种全新的自主学习理念，转变传统阅读习惯，以促进学生主动意义构建为导向。
        </div>
      </div>
    </div>
    <div class="register">
      <div class="button" @click="understand">
        进一步了解素养植根<img
        class="img"
        src="../../assets/home/30.png"
        alt=""
      />
      </div>
    </div>
    <!-- <a-modal
        title="作品提交延期通知"
        :visible="timeGo"
        :width=800
        :dialog-style="{ top: '150px' }"
        @ok="timeGoOk"
        @cancel="timeGoCancel"
        >
        <div>
            <div class="time-content">
                为配合各校疫情防控工作，保证2022年首届“师生共研”微课制作大赛顺利进行，经大赛组委会研究决定，此次微课制作大赛作品提交日期由原定的3月21日延长至3月31日截止，比赛其他要求及提交方式不变。
            </div>
            <div class="time-content">
                特此通知。
            </div>
            <div class="time-content">
                望各位参赛老师及时关注相关通知，不要错过提交作品的最后时间哦～预祝大家都能取得优异成绩。
            </div>
        </div>
    </a-modal> -->
  </div>
</template>

<script>
import axios from 'axios'
import HeadTop from '@/components/HeadTop.vue'

export default {
  name: 'home',
  data() {
    return {
      bannerList: [],
      basePath: this.$basePath,
      autoplay: true,
      time: 3,
      timer: null,
      nowTime: (new Date()).valueOf(),
      isIE: false
      //   timeGo: true
    }
  },
  watch: {},
  mounted() {
    // this.getBanner()
  },
  methods: {
    // timeGoOk(){
    //     this.timeGo = false
    // },
    // timeGoCancel(){
    //     this.timeGo = false
    // },
    goActivityPage() {
      window.open(
        this.$stuUrl + 'page/qt/research/index.html', '_blank'
      )
    },
    goPage() {
      const a = document.createElement('a')
      a.setAttribute('href', './detail.html')
      a.setAttribute('target', '_blank')
      window.open(a)
    },
    getBanner() {
      const _this = this
      axios({
        url: this.$baseUrl + 'official/banner/getList',
        method: 'POST',
        data: {}
      }).then((res) => {
        if (res && res.data && res.data.resultCode === 0) {
          _this.bannerList = res.data.resultData
        }
      })
    },
    useNow() {
      const user_info = localStorage.getItem('user_info')
      if (user_info && user_info.roleids == 5) {
        window.location.replace(
          this.$teaUrl + 'page/qt/tea/index.html'
        )
      } else if (user_info && user_info.roleids != 5) {
        window.location.replace(
          this.$stuUrl + 'page/qt/stu/index.html'
        )
      } else {
        this.$router.push({name: 'login'})
      }
    },
    understand() {
      this.$router.push({name: 'product'})
      sessionStorage.setItem('routeIndex', 1)
    }
  },
  beforeDestroy() {
    // clearInterval(this.timer)
    // this.timer = null
  },
  components: {
    HeadTop
  }
}
</script>

<style scoped lang="less">
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.time-title {
  line-height: 50px;
  color: #000;
  font-size: 22px;
  text-align: center;
}

.time-content {
  line-height: 30px;
  color: #666;
  font-size: 16px;
  text-indent: 24px;
}

/deep/ .ant-modal-title {
  margin: 0;
  color: #333;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  word-wrap: break-word;
  text-align: center;
}

.home {
  background: #f6f6f6;

  .banner {
    width: 100%;
    height: 600px;

    .bannerDiv {
      height: 600px;
      width: 100%;

      .bannerImg {
        width: 100%;
        height: 600px;
        background: url("../../assets/home/banner1.jpg") no-repeat;
        background-size: cover;
        background-position: center; /* 使背景图片在div中居中显示 */
        display: flex;
        flex-direction: column; /* 设定主轴方向为垂直方向 */
       // justify-content: center; /* 使子元素在主轴上居中 */
        align-items: center; /* 如需，也可使子元素在交叉轴上居中 */

        // min-height: 600px;
        .mainTitle {
          // position: absolute;
          height: 80px;
          text-align: center;
          font-size: 60px;
          font-family: STKaitiSC-Black, STKaitiSC,KaiTi;
          font-weight: 900;
          color: #000000;
          line-height: 80px;
          margin-top: 170px;
          // top: 30%;
          // left: 50%;
          // margin-left: -330px;
        }

        .smallTitle {
          text-align: center;
          height: 40px;
          font-size: 28px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #433502;
          margin-top: 10px;
        }

        .useNow {
          width: 140px;
          height: 34px;
          line-height: 34px;
          background: url("../../assets/home/02.png") no-repeat;
          background-size: 100% 100%;
          color: #fff;
          font-size: 16px;
          font-family: STSongti-SC-Bold, STSongti-SC;
          font-weight: bold;
          cursor: pointer;
          text-align: center;
          margin-top: 20px;
          // &:hover{
          //   box-shadow: 0px 2px 5px 0px #DB5647;
          // }
        }
      }
    }
  }

  .moshi {
    height: 982px;
    width: 100%;
    background: #f6f6f6;

    .title {
      text-align: center;
      padding-top: 80px;
      font-weight: bold;
      color: #000000;
      font-size: 28px;
    }

    .mess {
      width: 1140px;
      margin: 50px auto;

      .messTop {
        height: 240px;
        display: flex;

        .user {
          width: 320px;
          height: 100%;
          display: inline-block;
          background: url("../../assets/home/03.png") no-repeat;
          background-size: 100% 100%;
        }

        .speak {
          width: 244px;
          height: 239px;
          background: url("../../assets/home/04.png") no-repeat;
          background-size: 100% 100%;
          margin-top: 1px;
          padding: 0px 30px;
          color: #fff;

          .userName {
            margin-top: 63px;
            font-weight: bold;
            font-size: 20px;
            line-height: 25px;
            font-family: STSongti-SC-Regular, STSongti-SC;
          }

          .userTitle {
            font-weight: bold;
            font-size: 12px;
            line-height: 30px;
            font-size: 12px;
            opacity: 0.7;
          }

          .userMess {
            font-size: 12px;
            line-height: 20px;
            margin-top: 2px;
            opacity: 0.7;
          }
        }

        .mainText {
          width: 590px;
          height: 239px;
          text-align: justify;
          background: url("../../assets/home/05.png") no-repeat;
          margin-top: 1px;
          background-size: 100% 100%;
          padding: 88px 50px;
          font-size: 20px;
          line-height: 32px;
          color: #000;
          font-weight: bold;

          .color {
            color: #c16f52;
            font-size: 20px;
            font-weight: 600;
          }
        }
      }

      .messBot {
        display: flex;
        margin-top: 70px;
        height: 700px;

        .t_img {
          margin-top: 150px;
          width: 200px;
          height: 200px;
          background: url("../../assets/home/06-1.png") no-repeat;
          background-size: 100% 100%;
        }

        .t_img1 {
          margin-top: 150px;
          width: 200px;
          height: 200px;
          border-radius: 50%;

          .ant-carousel .slick-slide {
            text-align: center;
            height: 160px;
            line-height: 160px;
            overflow: hidden;
          }

          img {
            width: 160px;
            height: 160px;
            margin-left: 20px;
          }
        }

        .t_text {
          margin-top: 10px;
          height: 30px;
          text-align: center;
          font-size: 18px;
          font-family: STSongti-SC-Bold, STSongti-SC;
          font-weight: bold;
          color: #000000;
          line-height: 30px;
        }

        .t_t {
          margin: 0px 30px 0px 50px;
          margin-top: 190px;
          width: 165px;
          height: 112px;
          background: url("../../assets/home/07.png") no-repeat;
          background-size: 100% 100%;
        }

        .s_img {
          width: 675px;
          height: 490px;
          background: url("../../assets/home/c.png") no-repeat;
          position: relative;
          background-size: 100% 100%;

          li {
            width: 120px;
            height: 120px;
            transition: width 0.5s, height 0.5s, transform 0.5s;

            &:hover {
              transform: scale(1.3);
            }

            img {
              display: block;
              width: 40px;
              height: 34px;
              text-align: center;
              margin-top: 20px;
              margin-left: 40px;
              z-index: 9;
            }

            .l_t {
              color: #fff;
              text-align: center;
              font-size: 14px;
              font-weight: bold;
              line-height: 20px;
              margin-top: 10px;
            }
          }

          .i8 {
            position: absolute;
            top: 184px;
            left: 10px;
            background: url("../../assets/home/08.png") no-repeat;
            background-size: 100% 100%;

            img {
              margin-left: 45px;
              margin-top: 30px;
            }
          }

          .i9 {
            position: absolute;
            top: 84px;
            left: 130px;
            background: url("../../assets/home/09.png") no-repeat;
            background-size: 100% 100%;

            .l_t {
              color: #fff;
              text-align: center;
              font-size: 14px;
              font-weight: bold;
              line-height: 20px;
              margin-top: 4px;
            }
          }

          .i10 {
            position: absolute;
            top: 34px;
            left: 280px;
            background: url("../../assets/home/10.png") no-repeat;
            background-size: 100% 100%;

            img {
              margin-top: 30px;
            }
          }

          .i11 {
            position: absolute;
            top: 84px;
            left: 420px;
            background: url("../../assets/home/11.png") no-repeat;
            background-size: 100% 100%;

            img {
              margin-top: 30px;
            }
          }

          .i12 {
            position: absolute;
            top: 184px;
            left: 545px;
            background: url("../../assets/home/12.png") no-repeat;
            background-size: 100% 100%;

            img {
              margin-top: 30px;
              margin-left: 45px;
            }
          }

          .i13 {
            position: absolute;
            top: 285px;
            left: 430px;
            background: url("../../assets/home/13.png") no-repeat;
            background-size: 100% 100%;

            img {
              margin-top: 30px;
            }
          }

          .i14 {
            position: absolute;
            top: 335px;
            left: 280px;
            background: url("../../assets/home/14.png") no-repeat;
            background-size: 100% 100%;

            img {
              margin-top: 30px;
            }
          }

          .i15 {
            position: absolute;
            top: 285px;
            left: 130px;
            background: url("../../assets/home/15.png") no-repeat;
            background-size: 100% 100%;

            img {
              margin-top: 30px;
            }
          }

          .iText {
            width: 250px;
            position: absolute;
            top: 232px;
            left: 225px;
            font-size: 18px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 900;
            color: #000000;
            line-height: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  .zhuanti {
    height: 720px;
    width: 100%;
    background: #fff;

    .title {
      text-align: center;
      padding-top: 70px;
      font-weight: bold;
      color: #000000;
      font-size: 28px;
    }

    .zt_mess {
      display: flex;
      width: 1140px;
      height: 240px;
      margin: 50px auto;
      margin-bottom: 70px;

      .user {
        width: 320px;
        height: 100%;
        display: inline-block;
        background: url("../../assets/home/16.png") no-repeat;
        background-size: 100% 100%;
      }

      .speak {
        width: 240px;
        height: 240px;
        background: url("../../assets/home/17.png") no-repeat;
        background-size: 100% 100%;
        padding: 0px 30px;
        color: #fff;
        text-align: right;

        .userName {
          margin-top: 63px;
          font-weight: bold;
          font-size: 20px;
          line-height: 25px;
          font-family: STSongti-SC-Regular, STSongti-SC;
        }

        .userTitle {
          font-weight: bold;
          font-size: 12px;
          line-height: 30px;
          font-size: 12px;
          opacity: 0.7;
        }

        .userMess {
          font-size: 12px;
          line-height: 20px;
          margin-top: 2px;
          opacity: 0.7;
        }
      }

      .mainText {
        text-align: justify;
        width: 590px;
        height: 239px;
        background: url("../../assets/home/18.png") no-repeat;
        margin-top: 1px;
        background-size: 100% 100%;
        padding: 25px 50px;
        font-size: 16px;
        line-height: 32px;
        color: #000;
        font-weight: bold;

        .color {
          color: #93a870;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }

    .zt_bot {
      height: 180px;
      display: flex;
      width: 1140px;
      margin: 0 auto;

      .botLeft {
        width: 490px;
        height: 180px;
        background: url("../../assets/home/19.png");
        background-size: 100% 100%;
        text-align: right;
        line-height: 180px;
        color: #fff;
        font-size: 32px;
        font-family: STSongti-SC-Black, STSongti-SC;

        &:hover {
          box-shadow: 0px 0px 20px 0px rgb(186 186 173 / 20%);
        }
      }

      .botRight {
        display: flex;
        margin-top: 15px;

        li {
          margin-left: 10px;
          width: 180px;
          height: 150px;
          position: relative;
          cursor: pointer;

          .text {
            text-align: center;
            margin-top: 65px;
            font-weight: bold;
            color: #000000;
            line-height: 20px;
            font-size: 18px;
            position: absolute;
            left: 25px;
          }

          .explain {
            background: #7f8f4a;
            text-align: justify;
            width: 100%;
            height: 100%;
            z-index: 9;
            position: absolute;
            padding: 5px;
            display: none;
            line-height: 15px;
            color: #fff;

            span {
              transform: scale(0.7);
              font-size: 10px;
            }
          }

          &:first-child {
            margin-left: 0px;
          }

          &:hover {
            .explain {
              display: block;
            }
          }
        }

        .zt1 {
          background: url("../../assets/home/20.png") no-repeat;
          background-size: 100% 100%;
          text-align: justify;
        }

        .zt2 {
          background: url("../../assets/home/21.png") no-repeat;
          background-size: 100% 100%;
        }

        .zt3 {
          background: url("../../assets/home/22.png") no-repeat;
          background-size: 100% 100%;
        }

        .zt4 {
          background: url("../../assets/home/23.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }

  .zhuanjia {
    width: 100%;
    height: 760px;
    // background: #f6f6f6;
    background: url("../../assets/home/24.png") no-repeat;
    background-size: 100% 100%;

    .bgc {
      margin: 0 auto;
      width: 1140px;
      height: 100%;

      .ti {
        height: 30px;
        line-height: 30px;
        font-weight: bold;
        color: #000000;
        font-size: 28px;
        background: #f6f6f6;
        text-align: center;
        padding-top: 70px;
      }

      .con {
        display: flex;
        width: 1140px;
        height: 600px;
        position: relative;
        margin-top: 140px;

        li {
          width: 20%;
          height: 350px;
          position: absolute;
          box-shadow: 0px 2px 10px 0px rgba(219, 206, 188, 0.5);

          .zj_t {
            width: 100%;
            padding: 20px 25px;
            height: 100px;
            background: #fff;
            font-size: 18px;
            font-family: STSongti-SC-Bold, STSongti-SC;
            font-weight: bold;
            color: #000000;
            line-height: 30px;
            text-align: center;
          }

          &:hover {
            cursor: pointer;
            transform: scale(1.2);
            z-index: 99;
          }
        }

        .li1 {
          top: 0px;
          left: 0px;

          .zj1 {
            background: url("../../assets/home/25.png") no-repeat;
            background-size: 100% 100%;
            width: 100%;
            height: 250px;
          }
        }

        .li2 {
          top: 105px;
          left: 20%;

          .zj2 {
            background: url("../../assets/home/26.png") no-repeat;
            background-size: 100% 100%;
            width: 100%;
            height: 250px;
          }
        }

        .li3 {
          top: 0px;
          left: 40%;

          .zj3 {
            background: url("../../assets/home/27.png") no-repeat;
            background-size: 100% 100%;
            width: 100%;
            height: 250px;
          }
        }

        .li4 {
          top: 105px;
          left: 60%;

          .zj4 {
            background: url("../../assets/home/28.png") no-repeat;
            background-size: 100% 100%;
            width: 100%;
            height: 250px;
          }
        }

        .li5 {
          top: 0px;
          left: 80%;

          .zj5 {
            background: url("../../assets/home/29.png") no-repeat;
            background-size: 100% 100%;
            width: 100%;
            height: 250px;
          }
        }
      }
    }
  }

  .lianhe {
    background: #fff;

    .ti {
      height: 180px;
      line-height: 180px;
      font-weight: bold;
      color: #000000;
      font-size: 28px;
      text-align: center;
    }

    .cont {
      width: 1140px;
      height: 400px;
      margin: auto;

      .lhUl {
        height: 280px;
        width: 900px;
        margin: auto;
        display: flex;

        .lhLi1,
        .lhLi3,
        .lhLi5 {
          text-align: center;
          width: 300px;

          .logoImg {
            width: 160px;
            height: 160px;
            background: #ffffff;
            box-shadow: 0px 0px 20px 0px rgba(186, 186, 173, 0.4);
            border-radius: 20px;
            text-align: center;
            margin: auto;

            .imgLogo1 {
              margin-top: 29px;
              width: 134px;
              height: 102px;
            }

            .imgLogo2 {
              margin-top: 12px;
              width: 134px;
              height: 135px;
            }

            .imgLogo3 {
              margin-top: 37px;
              width: 134px;
              height: 93px;
            }
          }

          .logoName {
            .nameDetail {
              margin-top: 30px;
              text-align: center;
              font-weight: 400;
              color: #000000;
              line-height: 24px;
              font-size: 16px;
            }
          }
        }
      }

      .lhMess {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
      }
    }
  }

  .register {
    height: 90px;
    width: 100%;
    text-align: center;
    background: #fff;

    .button {
      margin: auto;
      width: 300px;
      height: 50px;
      line-height: 47px;
      border-radius: 32px;
      border: 1px solid #db5647;
      // box-shadow: 0px 2px 20px 0px #DBCEBC;
      font-family: STSongti-SC-Bold, STSongti-SC;
      border-radius: 50px;
      font-size: 20px;
      color: #db5647;
      font-weight: bold;
      cursor: pointer;

      .img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        margin-top: -3px;
      }
    }
  }
}
</style>
<style scoped>
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 600px;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}

.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}

.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>
